import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Tooltip from 'rc-tooltip'
import './SupplierNote.scss'
import 'element-closest-polyfill'
import 'rc-tooltip/assets/bootstrap_white.css'

class SupplierNote extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      position: 'bottom'
    }

    this.tooltipRef = React.createRef()
    this.renderContent = this.renderContent.bind(this)
  }

  componentDidMount () {
    this.updateTooltipPosition()
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.updateTooltipPosition()
    }
  }

  updateTooltipPosition () {
    if (this.tooltipRef.current) {
      const el = this.tooltipRef.current.tooltipRef
      const parentCarousel = el.closest('.slick-list')

      if (parentCarousel) {
        let position = 'top'
        const firstItemPositionInCarousel = parentCarousel.querySelector('.slick-active.slick-current').getAttribute('data-index')
        const lastItemPositionInCarousel = parentCarousel.querySelectorAll('.slick-active').length - 1
        const thisSliderItem = el.closest('.slick-active')
        const thisSliderItemIndex = thisSliderItem.getAttribute('data-index')

        if (thisSliderItemIndex === firstItemPositionInCarousel) {
          position = 'right'
        } else if (thisSliderItemIndex === lastItemPositionInCarousel.toString()) {
          position = 'left'
        }

        this.setState({ position })
      }
    }
  }

  renderContent () {
    const { note, i18n, t } = this.props
    const comment = note[`comment_${i18n.language}`]

    return (
      <div>
        {note.type ? (
          <>
            <h3 className='note-tooltip--title'>
              {t(`supplier.home.tabs.details.notes.${note.type}`)}
            </h3>
            <p>{comment}</p>
          </>
        ) : (
          <p>{this.props.note}</p>
        )}
      </div>
    )
  }

  render () {
    return (
      this.props.note ? (
        <Tooltip
          placement={this.state.position}
          trigger={['hover', 'focus']}
          overlay={this.renderContent()}
          arrowContent={<div className='rc-tooltip-arrow-inner' />}
        >
          {this.props.children
            ? (
              <div tabIndex='0'>
                {this.props.children}
              </div>
            )
            : (
              <div tabIndex='0' className='note-icon' role='tooltip' aria-label={this.props.t(`supplier.home.tabs.details.notes.${this.props.note.type}`) + this.props.note[`comment_${this.props.i18n.language}`]}>
                <i className={`fas fa-info-circle note-type--${this.props.note.type}`} />
              </div>
            )}
        </Tooltip>
      ) : null
    )
  }
}

SupplierNote.propTypes = {
  note: PropTypes.object,
  i18n: PropTypes.object,
  supplierId: PropTypes.number
}

export default withTranslation()(SupplierNote)
